<template>
  <div class="input-container" :class="{ error }">
    <label v-if="type === 'checkbox'" class="input-checkbox">
      <input type="checkbox" v-model="value" :disabled="disabled" />
      <IconCheck class="input-checkbox__icon" @change="emit('change')" />
      <span class="input-checkbox__name">{{ name }}</span>
    </label>
    <div v-else class="input-box" :class="{ error, disabled, 'empty': isEmpty, 'input-textarea': type === 'textarea'}">
      <ClientOnly v-if="type === 'datepicker'">
        <DatePicker
          class="input"
          :disabled="disabled"
          ref="input"
          :type="datepickerType"
          :format="datepickerFormat"
          :value-type="datepickerValueType"
          v-model:value="value"
          @change="emit('change')"
          v-bind="$attrs"
        />
      </ClientOnly>
      <Multiselect
        class="input"
        :disabled="disabled"
        ref="select"
        v-else-if="isSelect"
        v-model="value"
        :mode="type === 'select' ? 'single' : 'multiple'"
        :searchable="search !== false"
        :valueProp="search?.prop ?? 'id'"
        :trackBy="search?.track ?? 'name'"
        :label="search?.label ?? 'name'"
        :options="options"
        noResultsText="Ничего не найдено"
        noOptionsText="Нет данных"
        :id="uid"
        v-bind="$attrs"
        @change="emit('change')"
        :caret="false"
        :can-clear="false"
      />
      <textarea v-else-if="type === 'textarea'" class="input" ref="textarea" :id="uid" v-model="value" :maxlength="maxlength" :disabled="disabled" @input="onTextareaInput" @change="emit('change')"></textarea>
      <input v-else :type="type" :name="formName" class="input" ref="input" :id="uid" placeholder=" " :disabled="disabled" v-model="value" @input="emit('input')" @change="emit('change')">
      <div class="input-name" ref="inputName">{{ name }}</div>

      <div v-if="maxlength" ref="inputCol" class="input-col count">
        {{ value?.length ?? 0 }}/{{ maxlength }}
      </div>
      <div v-else-if="symbol || isSelect || $slots.symbol" ref="inputCol" class="input-col symbol" :class="{ 'input-col--interactive': interactiveSymbol, 'input-col--left': symbolLeft }">
        <template v-if="isSelect">
          <IconChevronDown />
        </template>
        <template v-else-if="symbol === 'coin'">₽</template>
        <template v-else-if="$slots.symbol">
          <slot name="symbol" />
        </template>
        <template v-else>
          {{ symbol }}
        </template>
      </div>
    </div>
    <slot name="afterInput" />
    <div class="input-desc" v-if="desc || error">{{ descValue }}</div>
  </div>
</template>

<script setup>
import { IconChevronDown, IconCheck, IconCurrencyRubel } from '@tabler/icons-vue'
import Multiselect from '@vueform/multiselect'
const Inputmask = process.client ? (await import('inputmask')).default : null

const props = defineProps(['type', 'name', 'modelValue', 'symbol', 'desc', 'error', 'disabled', 'processField', 'formName', 'search', 'options',
  'maxlength', 'inputmask', 'interactiveSymbol', 'symbolLeft', 'datepickerType', 'valueType', 'format'])
const emit = defineEmits(['update:modelValue', 'input', 'change'])

const uid = 'input' + getCurrentInstance().uid
const descValue = computed(() => props.error ? props.error : props.desc)
const isSelect = computed(() => ['multiselect', 'select'].includes(props.type))
const isEmpty = computed(() => !value.value)
const select = ref()
const inputName = ref()

const inputCol = ref()

const input = ref()
const value = computed({
  get: () => props.modelValue,
  set: val => {
    const value = props.processField ? props.processField(val) : val
    if (val !== '' && props.processField) (e => e && (e.value = value))(textarea.value ?? input.value)
    emit('update:modelValue', value)
  }
})

const datepickerFormat = computed(() => {
  if (props.format) return props.format
  switch (props.datepickerType) {
    case 'month': return 'MMMM YYYY'
    case 'year': return 'YYYY'
    case 'datetime': return 'DD.MM.YYYY HH:mm'
    case 'time': return 'HH:mm'
    default: return 'DD.MM.YYYY'
  }
})
const datepickerValueType = computed(() => {
  if (props.valueType) return props.valueType
  switch (props.datepickerType) {
    case 'year':
    case 'time':
      return datepickerFormat.value
    case 'datetime': return 'YYYY-MM-DD HH:mm:ss'
    default: return 'YYYY-MM-DD'
  }
})

const textarea = ref()
function onTextareaInput () {
  emit('input')
  resizeTextarea()
}
function resizeTextarea () {
  if (!textarea.value) return
  textarea.value.style.height = '24px'
  textarea.value.style.height = textarea.value.scrollHeight + 'px'
}
function applyInputmask () {
  const masks = {
    year: '9999'
  }
  const mask = masks[props.inputmask] ?? props.inputmask ?? masks[props.type]
  if (!mask) return

  Inputmask?.(mask, { showMaskOnHover: false, placeholder: '', clearIncomplete: true }).mask(input.value)
}
onMounted(() => {
  resizeTextarea()
  setTimeout(() => resizeTextarea())

  applyInputmask()

  watch([inputCol, select, textarea, input], () => {
    resizeTextarea()
    if (!inputCol.value) return
    (select.value?.input ?? textarea.value ?? input.value).style[props.symbolLeft ? 'paddingLeft' : 'paddingRight'] = inputCol.value.clientWidth + 'px'
    if (props.symbolLeft) inputName.value.style.left = inputCol.value.clientWidth + 'px'
  }, { immediate: true })
})
</script>

<style lang="scss">
  .input-container {
    display: flex;
    flex-direction: column;
  }

  .input-box {
    position: relative;
    background: var(--color-input-bg);
    border: 2px solid var(--color-input-bg);
    border-radius: 8px;
    transition: .1s border-color;

    & .input-col {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      padding: 8px 16px 8px 8px;
      display: flex;
      align-items: center;
      pointer-events: none;
      &.count {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--color-elements-tertiary);
        align-items: flex-end;
      }
      &--interactive {
        pointer-events: all;
      }
      &--left {
        right: unset;
        left: 0;
        padding: 8px 10px 8px 16px;
      }
    }

    & .input {
      display: block;
      position: static;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      height: 58px;
      min-height: 58px;
      border-radius: 0;
      font-family: inherit;
      background: none;
      border: none;
      padding: 26px 16px 8px;
      margin: 0;
      width: 100%;
      color: var(--color-elements-primary);
      cursor: inherit;
      box-shadow: none !important;
      resize: none;
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px var(--color-input-bg) inset !important;
        -webkit-text-fill-color: var(--color-elements-primary) !important;
      }
      &:focus {
        outline: none;
      }
      .multiselect-wrapper, .multiselect-search {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 26px 16px 8px;
        background: none;
      }
      .multiselect-single-label {
        padding: 8px 16px;
        display: flex;
        align-items: flex-end;
        height: 100%;
      }
      .multiselect-dropdown {
        background: var(--color-bg);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        border: none;
        overflow-y: auto;
        bottom: -2px;
        padding: 10px 0;
        z-index: 99999;
        .multiselect-option {
          padding: 5px 16px;
          font-weight: 500;
          font-size: 13px;
          line-height: 24px;
          letter-spacing: 1px;
          text-transform: uppercase;
          &.is-pointed, &.is-selected {
            background: var(--color-primary);
            color: #fff;
          }
          &.is-selected {
            font-weight: 700;
          }
        }
        .multiselect-no-results, .multiselect-no-options {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: var(--color-elements-secondary);
          padding: 16px;
        }
      }
      &.mx-datepicker {
        padding: 0;
        .mx-input-wrapper {
          position: static;
        }
        .mx-input {
          background: none;
          border: none;
          box-shadow: none;
          font-size: inherit;
          color: inherit;
          padding: 26px 16px 8px;
          height: auto;
          border-radius: 0;
          line-height: inherit;
          text-transform: capitalize;
        }
        .mx-icon-calendar, .mx-icon-clear {
          right: 16px;
          color: var(--color-elements-secondary);
        }
        &.disabled {
          .mx-icon-calendar, .mx-icon-clear {
            color: var(--color-elements-tertiary);
          }
        }
      }
    }
    & .input-name {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: var(--color-elements-secondary);
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
      transition: .1s transform, .1s color;
      pointer-events: none;
    }

    & .symbol {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-elements-tertiary);
      button {
        display: block;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }

    &:focus-within {
      border-color: var(--color-primary);
      & .input-name {
        color: var(--color-primary);
      }
    }
    &:focus-within .input-name, input.input:not(:placeholder-shown) ~ .input-name, &:not(.empty) .input-name {
      transform: translateY(calc(-50% - 10px));
    }

    &.input-textarea {
      height: auto;
      .input-name {
        transform: none;
        top: 14px;
      }
    }

    &.disabled {
      cursor: not-allowed;
      & .input-name {
        color: var(--color-elements-tertiary);
      }
      & .symbol {
        color: var(--color-elements-quantery);
      }
    }

    &.error {
      border-color: var(--color-error);
      & .input-name {
        color: var(--color-error);
      }
    }
  }

  .input-checkbox {
    display: flex;
    align-items: center;
    gap: 13px;
    cursor: pointer;
    input {
      display: none;
    }
    &__icon {
      width: 24px;
      height: 24px;
      color: transparent;
      background: var(--color-bg-secondary);
      border-radius: 4px;
      flex-shrink: 0;
    }
    &__name {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: var(--color-elements-primary);
      white-space: nowrap;
      user-select: none;
    }
    input:checked + &__icon {
      color: var(--color-bg);
      background: var(--color-primary);
    }
  }

  .input-desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-elements-secondary);
    margin-top: 8px;

    .input-container.error & {
      color: var(--color-error);
    }
  }
</style>
